import React from "react";

function Stats() {
  return (
    <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
      <div
        className="md:hidden bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-teal-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">
              View the Full Graph Plot On Large Screen
            </p>
            <p className="text-sm">
              For the best experience and to view the graph and plot clearly,
              please open this page in larger screen.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col p-4">
        <div
          //   className="flex-grow w-full mb-16 relative overflow-hidden"
          className="flex-grow flex flex-col p-4 overflow-hidden"
          style={{ height: "calc(100vh - 64px)" }}
        >
          <iframe
            // className="absolute top-20 left-0 w-full h-full border-0"
            className="w-full h-full border-0"
            src="http://127.0.0.1:8056/"
            title="Stats"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Stats;

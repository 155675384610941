import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import Datasets from "./datatable/DisplayTarget";
import { CustomDialog } from "../../components/common/dialog/CustomDialog";

const Application = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [individualApplication, setIndividualApplication] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [businessLine, setBusinessLine] = useState("");
  const [useDescription, setUseDescription] = useState("");
  const [openedEULAXchem, setOpenedEULAXchem] = useState(false);
  const [openedEULAHitgen, setOpenedEULAHitgen] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);

  const [users, setUsers] = useState([
    { firstName: "", lastName: "", email: "", role: "" },
  ]);
  const [hitgenAccepted, setHitgenAccepted] = useState(false);
  const [xchemAccepted, setXchemAccepted] = useState(false);

  const handleUserChange = (index, field, value) => {
    const updatedusers = [...users];
    updatedusers[index][field] = value;

    setUsers(updatedusers);
  };

  const addUser = () => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  };

  const removeUser = (index) => {
    setUsers(users.filter((_, i) => i !== index));
  };

  const checkWithSubmitterEmail = (users, submitterEmail) => {
    if (!users || !Array.isArray(users) || !submitterEmail) {
      throw new Error(
        "Invalid arguments: users must be an array, and submitterEmail is required."
      );
    }
    submitterEmail = submitterEmail.toLowerCase();

    return users.some((user) => {
      const email = user.email?.toLowerCase();

      return email === submitterEmail;
    });
  };

  function checkDuplicateEmails(users) {
    const emailCount = {};

    // Count the occurrences of each email
    users.forEach((user) => {
      const email = user.email.toLowerCase();

      emailCount[email] = (emailCount[email] || 0) + 1;
    });

    // Check if any email appears more than once
    for (const email in emailCount) {
      if (emailCount[email] > 1) {
        return true; // At least 2 users have the same email
      }
    }

    return false; // No duplicate emails found
  }

  const areFieldsEmpty = () => {
    // Check if general fields are empty

    if (
      !adminFirstName ||
      !adminLastName ||
      !(individualApplication || orgName) || // Only check orgName only if individualApplication is false
      !orgAddress ||
      !businessLine ||
      !useDescription ||
      (!hitgenAccepted && !xchemAccepted)
    ) {
      return true;
    }

    // Check if any user has empty details
    for (const user of users) {
      if (!user.firstName || !user.lastName || !user.email || !user.role) {
        return true;
      }
    }

    return false;
  };

  const handleSubmit = async () => {
    //submitted form
    users.forEach((user) => {
      user.email = user.email.toLowerCase();
    });

    const formData = {
      application: {
        submitterEmail: currentUser.email,
        submittedInfo: {
          adminFirstName,
          adminLastName,
          orgName,
          orgAddress,
          businessLine,
          useDescription,
        },
        individualApplication,
        users,
        decision: {
          hitgenAccepted: null,
          xchemAccepted: null,
          hitgenDecisionDate: null,
          xchemDecisionDate: null,
        },
        termsAndConditions: {
          hitgenAccepted: hitgenAccepted,
          xchemAccepted: xchemAccepted,
        },
      },

      admin: {
        email: currentUser.email,
        firstName: adminFirstName,
        lastName: adminLastName,
        role: "Admin",
      },
    };
    const checkSubmitterEmail = checkWithSubmitterEmail(
      formData.application.users,
      currentUser.email
    );
    if (checkSubmitterEmail) {
      setDialogVisible({
        visible: true,
        message: `${currentUser.email} is already a part of the application, please provide a different email address`,
        header: "Duplicate Email",
      });
      // alert("Please provide a different email address for each user");
      return;
    }

    const checkuserEmails = checkDuplicateEmails(formData.application.users);
    if (checkuserEmails) {
      setDialogVisible({
        visible: true,
        message:
          "Please verify provided email, a different email address is used for each user",
        header: "Duplicate Email",
      });
      // alert("Please provide a different email address for each user");
      return;
    }

    try {
      const res = await axios.post("/api/application/submit", formData);

      if (res.data !== "Application successfully saved") {
        setDialogVisible({
          visible: true,
          message: res.data,
          header: "Duplicate Error",
        });
      } else {
        alert(res.data);
        navigate("/datasets");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <div className="py-4 md:py-28 px-4 md:px-32 w-4/5 mx-auto">
      {dialogVisible.visible && (
        <CustomDialog
          dialogVisible={dialogVisible.visible}
          setDialogVisible={setDialogVisible}
          message={dialogVisible.message}
          header={dialogVisible.header}
        />
      )}
      <h2 className="text-3xl text-center font-semibold mb-8 text-gray-700">
        Data Acess Registration Form
      </h2>
      <p className="text-sm font-bold text-gray-700 mb-2">
        Please complete and submit the following information to request the
        creation of an administrator account to obtain access to the Datasets
        for yourself or your organization. Please note that if you are
        requesting access for your organization, you must be able to agree to
        the terms of the End User License Agreements below on behalf of your
        organization. If you are not authorized to do, please have someone from
        your organization with such authority create the administrator account.
      </p>

      <div className="shadow-md border-gray-200 border-2 rounded px-8 pt-6 pb-8 mb-2">
        <p className="text-sm font-bold text-gray-700 mb-2">
          Fields marked with
          <span className="after:content-['*'] after:ml-1 after:text-red-500 font-bold" />{" "}
          are mandatory
        </p>
        <div className="flex flex-row justify-center mb-4">
          <div className="">
            <input
              type="checkbox"
              onChange={() => {
                setIndividualApplication(!individualApplication);
                setOrgName("");
                setUsers([]);
              }}
              checked={individualApplication}
            />
          </div>
          <div className="mx-3">
            <label className="text-gray-700 text-sm">
              <p
                className={
                  individualApplication ? "font-bold text-red-500" : ""
                }
              >
                Please click here if you are requesting access to the Datasets
                in your own capacity and not registering on behalf of an
                organization. Please note that if you check this box, you
                directly undertake the responsibilities of the “User” under the
                End User License Agreements below and no rights to receive or
                use the Datasets will be granted to any organizations or
                colleagues with whom you may work.
              </p>
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="adminFirstName"
            className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
          >
            {individualApplication
              ? "Your First Name"
              : "Administrator First Name"}
          </label>
          <InputText
            value={adminFirstName}
            onChange={(e) => setAdminFirstName(e.target.value)}
            id="adminFirstName"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="adminLastName"
            className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
          >
            {individualApplication
              ? "Your Last Name"
              : "Administrator Last Name"}
          </label>
          <InputText
            value={adminLastName}
            onChange={(e) => setAdminLastName(e.target.value)}
            id="adminLastName"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {!individualApplication && (
          <div className="mb-4">
            <label htmlFor="orgName" className="block text-gray-700 mb-2">
              <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                Legal Name of Proposed Corporation/Organization
              </span>
              <p className="text-xs text-gray-500">
                If you are proposing to obtain access to the Datasets for use by
                your organization, please provide the organization’s legal name,
                which will be the proposed “User” of the Datasets for purposes
                of the End User License Agreements below.
              </p>
            </label>
            <InputText
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              id="orgName"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}

        <div className="mb-4">
          <label htmlFor="orgAddress" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              {individualApplication
                ? "Address"
                : "Address of Corporation/Organization"}
            </span>
            <p className="text-xs text-gray-500">
              {individualApplication
                ? "Please provide your address here"
                : "Please provide the legal address for the proposed Corporation/Organization"}
              {/* Please provide the legal address for the proposed “User”
              Corporation/Organization. If you are the proposed “User” as an
              individual, provide your address here. */}
            </p>
          </label>
          <InputText
            value={orgAddress}
            onChange={(e) => setOrgAddress(e.target.value)}
            id="orgAddress"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="businessLine" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              {individualApplication
                ? "Current Role"
                : "Corporation/Organization Line(s) of Business"}
            </span>
            <p className="text-xs text-gray-500">
              {individualApplication
                ? "provide a description of your current role(s) and qualifications "
                : null}
            </p>
          </label>
          <InputText
            value={businessLine}
            onChange={(e) => setBusinessLine(e.target.value)}
            id="businessLine"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {!individualApplication && (
          <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 border-gray-100 border-2">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                  “User” Corporation/Organization Employees Requiring Access
                </span>
                <p className="text-xs text-gray-500">
                  Please provide the names, email addresses, and titles/roles
                  for the employees in your organization requiring access. Once
                  approved, those individuals will be able to view and download
                  the Datasets by logging in to AIRCHECK. If you are the
                  proposed “User” as an individual person, do not complete this
                  section as only you will be provided access if approved.
                </p>
              </label>
              {users.map((user, index) => (
                <div key={index} className="md:grid md:grid-cols-5 md:gap-3">
                  <div className="relative z-0 group">
                    <InputText
                      value={user.firstName}
                      type="text"
                      placeholder=" "
                      onChange={(e) =>
                        handleUserChange(index, "firstName", e.target.value)
                      }
                      id={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First Name
                    </label>
                  </div>

                  <div className="relative z-0 group">
                    <InputText
                      value={user.lastName}
                      type="text"
                      onChange={(e) =>
                        handleUserChange(index, "lastName", e.target.value)
                      }
                      placeholder=""
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="relative z-0 group">
                    <InputText
                      value={user.email}
                      type="email"
                      onChange={(e) =>
                        handleUserChange(index, "email", e.target.value)
                      }
                      placeholder=""
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      typeof="email"
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                    <p className="invisible peer-invalid:visible text-pink-400 text-sm">
                      Please provide a valid email address.
                    </p>
                  </div>
                  <div className="relative z-0 group">
                    <InputText
                      value={user.role}
                      type="text"
                      onChange={(e) =>
                        handleUserChange(index, "role", e.target.value)
                      }
                      placeholder=""
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Role
                    </label>
                  </div>

                  <Button
                    className="text-red-600 w-8"
                    onClick={() => removeUser(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                      />
                    </svg>
                  </Button>
                </div>
              ))}
              <Button className="text-green-600 w-6" onClick={addUser}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                  />
                </svg>
              </Button>
            </div>
          </div>
        )}

        <div className="mb-4">
          <label htmlFor="useDescription" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              “Proposed Use” of Datasets
            </span>
            <p className="text-xs text-gray-500">
              Please provide a brief, non-confidential, high-level description
              of the machine learning algorithm(s) or other research
              approach(es) that the User proposes to employ in analyzing the
              Datasets in sufficient detail to enable a person skilled in the
              relevant field to readily understand the User’s proposed use(s).
            </p>
          </label>
          <InputTextarea
            value={useDescription}
            onChange={(e) => setUseDescription(e.target.value)}
            id="useDescription"
            rows={6}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <p className="text-xs text-gray-700 before:content-['*'] before:mr-1">
            <span className="underline underline-offset-1 font-bold">
              For X-Chem Datasets only
            </span>
            : please note that the Proposed Use provided here, if accepted, will
            govern the scope of the authorized use(s) of the X-Chem Datasets
            under the End User License Agreement below, and such description
            must be updated if the User proposes to make uses of the Datasets
            not encompassed by the original description provided.
          </p>
        </div>

        <div className="mb-8">
          <h1 className="font-extrabold text-gray-700"> HitGen Datasets</h1>
          <div className="flex flex-row justify-center mb-4">
            <div className="hitgen-tooltip">
              <input
                type="checkbox"
                onChange={() => setHitgenAccepted(!hitgenAccepted)}
                checked={hitgenAccepted}
                disabled={!openedEULAHitgen}
                className="disabled:opacity-50 disabled:cursor-not-allowed"
              />
              {!openedEULAHitgen && (
                <Tooltip
                  target=".hitgen-tooltip"
                  content="Review HitGen EULA before accepting"
                  position="left"
                />
              )}
            </div>
            <div className="ml-3">
              <label className="text-gray-700 text-sm">
                <p>
                  By clicking here, you hereby acknowledge having read and
                  understood the{" "}
                  <span onClick={() => setOpenedEULAHitgen(true)}>
                    <HitgenPDFLink />
                  </span>{" "}
                  and you hereby agree on behalf of the User that, if your
                  registration is accepted, the User shall be bound by all of
                  the terms and conditions thereof.
                </p>
              </label>
            </div>
          </div>
          <h1 className="font-extrabold text-gray-700 ml-1">X-Chem Datasets</h1>
          <div className="flex flex-row justify-center">
            <div className="xchem-tooltip">
              <input
                type="checkbox"
                onChange={() => setXchemAccepted(!xchemAccepted)}
                checked={xchemAccepted}
                disabled={!openedEULAXchem}
                className="disabled:opacity-50 disabled:cursor-not-allowed"
              />
              {!openedEULAXchem && (
                <Tooltip
                  target=".xchem-tooltip"
                  content="Review X-Chem EULA before accepting"
                  position="left"
                />
              )}
            </div>
            <div className="ml-3">
              <label className="text-gray-700 text-sm">
                <p>
                  By clicking here, you hereby acknowledge having read and
                  understood the{" "}
                  <span onClick={() => setOpenedEULAXchem(true)}>
                    <XchemPDFLink />
                  </span>{" "}
                  and you hereby agree on behalf of the User that, if your
                  registration is accepted, the User shall be bound by all of
                  the terms and conditions thereof.
                </p>
              </label>
            </div>
          </div>
        </div>

        <div className="flex-row">
          <Datasets hitgen={hitgenAccepted} xchem={xchemAccepted} />
        </div>

        <div className="flex items-center justify-center">
          <div className="tooltip-wrapper">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={areFieldsEmpty() || !currentUser}
              onClick={handleSubmit}
            >
              Submit
            </button>
            {(areFieldsEmpty() || !currentUser) && (
              <Tooltip
                target=".tooltip-wrapper"
                content="Verify you've completed all fields and you're logged in"
                position="top"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;

import React from 'react';
import BasicRoutes  from './Routes';
import Navbar from './components/common/navbar/Navbar';
import { AuthProvider } from './context/AuthContext';
import Footer from './components/common/footer/Footer';


function App() {
	return (
		<>
			<AuthProvider>
				<Navbar/>
				<BasicRoutes/>
				<Footer/>
			</AuthProvider>
		</>
	);
}

export default App;
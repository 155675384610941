// dropdown items to be populated in feedback page
export const labels_dict = [
  { name: "Bug", code: "bug" },
  { name: "Documentation", code: "documentation" },
  { name: "Enhancement", code: "enhancement" },
  { name: "Invalid", code: "invalid" },
  { name: "Question", code: "question" },
];

export const doc_links = {
  AIRCHECK: "AIRCHECK.md",
  "Chemical Representation": "Chemical-Representation.md",
  DEL: "DEL.md",
  "Data Overview": "Data-Overview.md",
  "Starting with ML": "Starting-with-ML.md",
};

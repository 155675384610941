import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const CustomDialog = ({ dialogVisible, setDialogVisible, header, message }) => {
  return (
    <div className="card flex justify-content-center h-screen">
      <Dialog
        modal
        header={header}
        position={"middle"}
        footer={
          <Button
            className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
            label="Close"
            onClick={() => setDialogVisible(false)}
            autoFocus
          />
        }
        style={{ width: "500px" }}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
      >
        <p>{message}</p>
      </Dialog>
    </div>
  );
};

export { CustomDialog };

import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import axios from "axios";

import { useAuth } from "../../../context/AuthContext";
import { handleAuthAction } from "../auth/AuthActions";
import { LoginChoiceDialog, LoginDialog, LoginDialogFooter } from "./LoginDialog";
import { SignUpDialog, SignUpDialogFooter } from "./SignupDialog";
import { ResetPasswordDialogFooter, ResetPasswordDialog } from "./ResetPasswordDialog";
import NotificationDialog from "./NotificationDialog";
import Dropdown from "./DocDropdown";

const Navbar = () => {
  // From AuthContext.js for various firebase login methods

  // Dialog popup variables (true = show)
  const [showLoginChoiceDialog, setShowLoginChoiceDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

  // Notifications for login/signup variables
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isError, setIsError] = useState(false); // Either success and error message

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setAuthentication] = useState(false);

  const { currentUser, loginWithGoogle, loginWithEmailPassword, signUpWithEmailPassword, resetPassword, logOut } =
    useAuth();
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const approvalCheck = async () => {
      try {
        const res = await axios.get(`/api/data/admin/${currentUser.email}`);

        if (!res || res.status !== 200) {
          setAuthentication(false);
          return;
        }
        // if (res && (res.hitgenAdmin || res.xchemAdmin)) {
        if (res && (res.data.hitgenAdmin || res.data.xchemAdmin || res.data.superAdmin)) {
          setAuthentication(true);
        }
      } catch (error) {
        console.error("Error fetching error: ", error);
      }
    };
    if (currentUser) {
      approvalCheck();
    }
  }, [currentUser, isAuthenticated]);

  // Navbar component
  return (
    <>
      <nav className="fixed top-0 z-10 w-full transition duration-300 ease-in-out bg-blue-700 shadow-md">
        <div className="flex flex-wrap items-center justify-between mx-auto p-3">
          <Link
            to="/"
            className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md  text-large`}
          >
            <span className="self-center text-2xl font-semibold whitespace-nowrap">AIRCHECK</span>
          </Link>

          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={toggleNavbar}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className={`w-full md:w-auto ${isOpen ? "block" : "hidden"} md:block`} id="navbar-default">
            <ul className=" flex flex-col md:flex-row p-4 md:p-0 rounded-lg md:mt-0bg-blue">
              <li>
                {currentUser && isAuthenticated && (
                  <Link to="/application-list">
                    <button
                      className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                    >
                      Application List
                    </button>
                  </Link>
                )}
              </li>
              <li>
                {currentUser && (
                  <Link to="/application">
                    <button
                      className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                    >
                      Apply for Datasets
                    </button>
                  </Link>
                )}
              </li>
              <li>
                <Link to="/datasets">
                  <button
                    className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                  >
                    Datasets
                  </button>
                </Link>
              </li>
              {/* <li>
                <Link to="/stats">
                  <button
                    className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                  >
                    Stats
                  </button>
                </Link>
              </li> */}
              <li>
                <Link to="/feedbackform">
                  <button
                    className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                  >
                    Feedback
                  </button>
                </Link>
              </li>

              <li>
                <Dropdown />
              </li>

              <li>
                <button
                  className={`text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md`}
                  onClick={() => handleAuthAction(currentUser, logOut, setShowLoginChoiceDialog)}
                >
                  {currentUser ? "Logout" : "Login"}
                </button>
              </li>
            </ul>
            {currentUser && <span className=" text-gray-200 text-xs md:absolute md:right-6">{currentUser.email}</span>}
          </div>
        </div>
      </nav>

      <Dialog
        header="Choose Login Method"
        visible={showLoginChoiceDialog}
        style={{ width: "500px" }}
        onHide={() => setShowLoginChoiceDialog(false)}
      >
        <LoginChoiceDialog
          loginWithGoogle={loginWithGoogle}
          setShowLoginChoiceDialog={setShowLoginChoiceDialog}
          setShowSignUpDialog={setShowSignUpDialog}
          setShowLoginDialog={setShowLoginDialog}
          setShowResetPasswordDialog={setShowResetPasswordDialog}
        />
      </Dialog>
      <Dialog
        header="Login"
        visible={showLoginDialog}
        style={{ width: "500px" }}
        footer={
          <LoginDialogFooter
            email={email}
            password={password}
            loginWithEmailPassword={loginWithEmailPassword}
            setNotificationMessage={setNotificationMessage}
            setIsError={setIsError}
            setShowNotification={setShowNotification}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
            setShowLoginDialog={setShowLoginDialog}
            setShowSignUpDialog={setShowSignUpDialog}
          />
        }
        onHide={() => setShowLoginDialog(false)}
      >
        <LoginDialog email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
      </Dialog>
      <Dialog
        header="Sign Up"
        visible={showSignUpDialog}
        style={{ width: "500px" }}
        footer={
          <SignUpDialogFooter
            name={name}
            email={email}
            password={password}
            signUpWithEmailPassword={signUpWithEmailPassword}
            setShowSignUpDialog={setShowSignUpDialog}
            setNotificationMessage={setNotificationMessage}
            setShowNotification={setShowNotification}
            setIsError={setIsError}
            setShowLoginChoiceDialog={setShowLoginChoiceDialog}
            setShowLoginDialog={setShowLoginDialog}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
          />
        }
        onHide={() => setShowSignUpDialog(false)}
      >
        {
          <SignUpDialog
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        }
      </Dialog>
      <Dialog
        header="Reset Password"
        visible={showResetPasswordDialog}
        style={{ width: "500px" }}
        footer={
          <ResetPasswordDialogFooter
            email={email}
            resetPassword={resetPassword}
            setNotificationMessage={setNotificationMessage}
            setIsError={setIsError}
            setShowNotification={setShowNotification}
            setShowLoginDialog={setShowLoginDialog}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
            setShowLoginChoiceDialog={setShowLoginChoiceDialog}
            setShowSignUpDialog={setShowSignUpDialog}
          />
        }
        onHide={() => setShowResetPasswordDialog(false)}
      >
        <ResetPasswordDialog email={email} setEmail={setEmail} />
      </Dialog>
      <Dialog
        header={!isError ? "Success" : "Failure"}
        visible={showNotification}
        style={{ width: "300px" }}
        onHide={() => setShowNotification(false)}
      >
        <NotificationDialog notificationMessage={notificationMessage} isError={isError} />
      </Dialog>
    </>
  );
};

export default Navbar;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useAuth } from "../../context/AuthContext";

const TopBar = () => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
        Applications
      </h1>
    </div>
  );
};

const ApplicationList = () => {
  const toast = useRef(null);
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [adminDetails, setAdminDetails] = useState({});

  const handleRowClick = (rowData) => {
    // Redirect to a different page with the clicked row's data
    navigate(`/single-application/${rowData.id}`);
  };

  function sortByAccepted(applications, provider) {
    const decisionField =
      provider === "xchem" ? "xchemAccepted" : "hitgenAccepted";

    return applications.sort((a, b) => {
      const aDecision = a.decision[decisionField];
      const bDecision = b.decision[decisionField];

      // First, compare the provider's decision
      if (aDecision === bDecision) {
        // If decisions are the same, sort by dateSubmitted (most recent first)
        return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
      }

      // null comes first
      if (aDecision === null) return -1;
      if (bDecision === null) return 1;

      // false comes before true
      return aDecision - bDecision;
    });
  }

  useEffect(() => {
    const getApplications = async () => {
      try {
        const res = await axios.get("/api/application/allapplications");
        const adminDetails = await axios.get(
          `/api/data/admin/${currentUser.email}`
        );
        setAdminDetails(adminDetails.data);
        let filteredApplications = [];
        // let sortedApplications = [];
        if (adminDetails.data.hitgenAdmin) {
          filteredApplications = res.data.filter(
            (app) => app.termsAndConditions.hitgenAccepted === true
          );
          filteredApplications = sortByAccepted(filteredApplications, "hitgen");
        } else if (adminDetails.data.xchemAdmin) {
          filteredApplications = res.data.filter(
            (app) => app.termsAndConditions.xchemAccepted === true
          );
          filteredApplications = sortByAccepted(filteredApplications, "xchem");
        } else {
          filteredApplications = res.data;
          filteredApplications = sortByAccepted(filteredApplications, "xchem");
        }

        setApplications(filteredApplications);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    if (currentUser && currentUser.email) {
      getApplications();
    }
  }, [currentUser]);

  return (
    <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
      <TopBar />
      <DataTable
        value={applications}
        paginator
        rows={20}
        rowsPerPageOptions={[20, 30, 50, 100]}
        onRowClick={(event) => handleRowClick(event.data)}
      >
        <Column
          field="submittedInfo.orgName"
          header="Organization"
          alignHeader="center"
          sortable
          body={(rowData) => {
            const isIndividual = !rowData.submittedInfo.orgName;
            return (
              <span style={{ color: isIndividual ? "#8971d0" : "#323232" }}>
                {isIndividual
                  ? "Individual Application"
                  : rowData.submittedInfo.orgName}
              </span>
            );
          }}
        />
        <Column
          field="submittedInfo.useDescription"
          header="Description"
          alignHeader={"center"}
        />
        <Column
          field="submittedInfo.adminFirstName"
          header="Submitter Name"
          body={(rowData) => {
            return rowData.submittedInfo
              ? `${rowData.submittedInfo.adminFirstName} ${rowData.submittedInfo.adminLastName}`
              : "";
          }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="submitterEmail"
          header="Submitter Email"
          alignHeader={"center"}
          sortable
        />
        {(adminDetails.superAdmin || adminDetails.hitgenAdmin) && (
          <Column
            body={(rowData) => (
              <span
                className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                  rowData.termsAndConditions.hitgenAccepted
                    ? rowData.decision.hitgenAccepted
                      ? "text-green-500"
                      : "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {rowData.termsAndConditions.hitgenAccepted ? (
                  rowData.decision.hitgenAccepted !== null ? (
                    rowData.decision.hitgenAccepted === true ? (
                      <b>Accepted</b>
                    ) : rowData.decision.hitgenAccepted === false ? (
                      <b>Rejected </b>
                    ) : (
                      String(rowData.decision.hitgenAccepted)
                    )
                  ) : (
                    "No Decision Made"
                  )
                ) : (
                  "N/A"
                )}
              </span>
            )}
            header="HitGen Status"
            alignHeader={"center"}
          />
        )}
        {(adminDetails.superAdmin || adminDetails.xchemAdmin) && (
          <Column
            body={(rowData) => (
              <span
                className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                  rowData.termsAndConditions.xchemAccepted
                    ? rowData.decision.xchemAccepted
                      ? "text-green-500"
                      : "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {rowData.termsAndConditions.xchemAccepted ? (
                  rowData.decision.xchemAccepted !== null ? (
                    rowData.decision.xchemAccepted === true ? (
                      <b>Accepted </b>
                    ) : rowData.decision.xchemAccepted === false ? (
                      <>
                        <b>Rejected</b>
                      </>
                    ) : (
                      String(rowData.decision.xchemAccepted)
                    )
                  ) : (
                    "No Decision Made"
                  )
                ) : (
                  "N/A"
                )}
              </span>
            )}
            header="X-Chem Status"
            alignHeader={"center"}
          />
        )}
      </DataTable>
    </div>
  );
};

export default ApplicationList;
